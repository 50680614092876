
import { defineComponent, ref, onBeforeMount, onMounted } from "vue";
import Countdown from "vue3-countdown";
export default defineComponent({
  components: { Countdown },
  name: "CustomAlert",
  props: {
    alertInfo: Object,
  },
  setup(props) {
    onBeforeMount(() => {
      console.log("总时间1:", props.alertInfo);
    });
    onMounted(() => {
      console.log("总时间2:", props.alertInfo);
    });
    return {};
  },
});
