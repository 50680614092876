import _ from "lodash";
import { ElMessageBox, ElMessageBoxOptions, ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { MutationNames } from "@/store/index";

import {
  getDrawPaper,
  getMockExamPaper,
  getMockExamAnswers,
  saveMockExamAnswer,
  getHistoryMockExam,
} from "@/services";
import { useStore } from "@/store";

export default (extra: any[] = []) => {
  const store = useStore();
  const router = useRouter();
  let paperId: any = undefined;
  const getExamPaper = async (exam: any) => {
    let examId = undefined;
    let getExamAnswerId = undefined;
    let hasAnswer = undefined;
    if (exam.examType === "exam") {
      // examId = store.state.examInfo?.active_exam?.id;
      examId = store.state.examInfo?.active_exam?.id;
      // getExamAnswerId = store.state.examInfo?.id;
      if (!examId) {
        return;
      }
      hasAnswer = !!store.state.examInfo?.answer_sheet;
    } else {
      examId = exam.exam_id;
      // examId = exam.id;
      getExamAnswerId = exam.id;
      if (!examId) {
        return;
      }
      hasAnswer = !!exam;
    }
    // const examAnswer = hasAnswer ? (await getExamAnswers(examId)) || {} : {};
    const examAnswer = hasAnswer
      ? (await getMockExamAnswers(getExamAnswerId)) || {}
      : {};
    const { answers = [] } = examAnswer;
    // const { papers = [] } = (await getPapers(examId!)) || {};
    let paper: any = [];
    if (exam.examType === "exam") {
      const paperData = (await getDrawPaper(examId!)) || {};
      paper = paperData.paper;
    } else {
      paper = [examAnswer.paper] || {};
    }
    const findSetAnswer = (question: any, paper: any, answers: any) => {
      question.paper_id = paper.id;
      const answer = answers.find(
        (an: any) => an.paper_id === paper.id && an.question_id === question.id
      );
      if (answer) {
        question.correctness = answer.correctness;
        question.answerScore = answer.score || 0;
        question.answer_params = answer.answer_params;
      }
    };
    for (let i = 0; i < paper.length; i++) {
      const paperData = paper[i];
      // const parperPart = await getPaper({
      const parperPart = await getMockExamPaper({
        paper_id: paperData.id,
      });
      if (parperPart.parts) {
        parperPart.parts.forEach((part: any) => {
          part.paper_id = paper.id;
          part.questions.forEach((question: any) => {
            findSetAnswer(question, paperData, answers);
            if (question.sub_questions && question.sub_questions.length > 0) {
              question.sub_questions.forEach((subQuestion: any) =>
                findSetAnswer(subQuestion, paperData, answers)
              );
            }
          });
        });
        paperData.parperParts = parperPart;
      }
    }
    store.commit(MutationNames.CHANGE_PAPERS, paper);
  };
  let submitLock = false;
  let submitServiceLock = false;
  let answer_sheet_id = 0;

  const submitPapers = async (
    mac: string,
    platform: number,
    timeEnd = false,
    onCancel?: any
  ) => {
    console.log("submitPapers_timeEnd", timeEnd);
    const papers = store.state.papers || [];
    if (papers.length <= 0) {
      console.error("没有找到试卷");
      return;
    }
    const examId = store.state.examInfo?.active_exam?.id;
    const answers: any[] = [];
    const getAllAnswer = () => {
      papers.forEach((paper) => {
        paperId = paper.id;
        const { parperParts = { parts: [] } } = paper;
        const { parts = [] } = parperParts;
        parts.forEach((part) => {
          const { questions = [] } = part;
          questions.forEach((question) => {
            if (question.sub_questions && question.sub_questions.length > 0) {
              question.sub_questions.forEach(
                (subQuestion: any) => {
                  if (subQuestion.answer_params !== undefined) {
                    if (subQuestion.answer_params.aiScore !== undefined) {
                      answers.push({
                        paper_id: paper.id,
                        question_id: subQuestion.id,
                        answer_params:
                          subQuestion.answer_params === undefined
                            ? {}
                            : subQuestion.answer_params,
                        ai_raw_score:
                          subQuestion.answer_params.aiScore === undefined
                            ? {}
                            : subQuestion.answer_params.aiScore,
                      });
                    } else {
                      answers.push({
                        paper_id: paper.id,
                        question_id: subQuestion.id,
                        answer_params:
                          subQuestion.answer_params === undefined
                            ? {}
                            : subQuestion.answer_params,
                      });
                    }
                  }
                }
                // subQuestion.answer_params !== undefined &&
                // answers.push({
                //   paper_id: paper.id,
                //   question_id: subQuestion.id,
                //   answer_params:
                //     subQuestion.answer_params === undefined
                //       ? {}
                //       : subQuestion.answer_params,
                // })
              );
            }
            if (question.answer_params !== undefined) {
              if (question.answer_params.aiScore !== undefined) {
                answers.push({
                  paper_id: paper.id,
                  question_id: question.id,
                  answer_params: question.answer_params,
                  ai_raw_score: question.answer_params.aiScore,
                });
              } else {
                answers.push({
                  paper_id: paper.id,
                  question_id: question.id,
                  answer_params: question.answer_params,
                });
              }
            }
          });
        });
      });
    };
    const submitAnswer = async () => {
      // saveExamAnswer(examId, {
      console.log("paper.id", paperId);
      console.log("submitServiceLock", submitServiceLock);
      if (submitServiceLock) {
        return;
      }
      submitServiceLock = true;
      saveMockExamAnswer(examId, {
        paper_id: paperId,
        create_device_mac: mac,
        create_device_type: platform,
        answers,
      })
        .then((data) => {
          store.commit(MutationNames.CHANGE_MOCKEXAMEND, false);
          console.log(12);
          extra.forEach((item: any) => {
            item();
          });
          console.log(34);
          answer_sheet_id = data.answer_sheet_id;
          const score = data.score === null ? 0 : data.score;
          // ElMessageBox.close();
          ElMessageBox({
            customClass: "exam-score-message-box",
            closeOnClickModal: false,
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,
            showCancelButton: true,
            confirmButtonText: "查看作答记录",
            cancelButtonText: "返回",
            title: "- 提交成功 -",
            message: `<div style="padding-top: 30px">本次模拟成绩：${score}分</div>`,
            beforeClose: (action, instance, done) => {
              console.log("action", action);
              // done();
              if (action === "confirm") {
                console.log("action", action);
                getHistoryMockExam({
                  id: answer_sheet_id,
                }).then((data: any) => {
                  const exam = data.items[0];
                  const examInfo = {
                    active_exam: { id: exam.exam_id, name: exam.exam.name },
                    answer_sheet: exam,
                  };
                  exam.examType = "histroy";
                  store.commit(MutationNames.CHANGE_EXAMINFO, examInfo);
                  getExamPaper(exam)
                    .then(() => {
                      console.log("then");
                      done();
                      router.push({
                        path: "/mockPaperHistory",
                        query: { examType: "histroy" },
                      });
                    })
                    .catch(() => {
                      console.log("catch");
                    });
                });
              } else {
                done();
                router.push({
                  path: "/mockExamPaperList",
                });
              }
            },
          });
          // .then(() => {
          //   getHistoryMockExam({
          //     id: answer_sheet_id,
          //   }).then((data: any) => {
          //     // const examData = [];
          //     const exam = data.items[0];

          //     const examInfo = {
          //       active_exam: { id: exam.exam_id, name: exam.exam.name },
          //       answer_sheet: exam,
          //     };
          //     exam.examType = "histroy";
          //     store.commit(MutationNames.CHANGE_EXAMINFO, examInfo);
          //     getExamPaper(exam)
          //       .then(() => {
          //         console.log("then");
          //         router.push({
          //           path: "/mockPaperHistory",
          //           query: { examType: "histroy" },
          //         });
          //       })
          //       .catch(() => {
          //         console.log("catch");
          //       });
          //   });
          // })
          // .catch(() => {
          //   ElMessageBox.close();
          //   router.push({
          //     path: "/mockExamPaperList",
          //   });
          // });

          // ElMessage({
          //   offset: 150,
          //   center: true,
          //   type: "success",
          //   message: `测评结束，已自动上传答卷`,
          //   onClose: () => {
          //     router.push({
          //       path: "/mockExamPaperList",
          //     });
          //   },
          // });
        })
        .catch(() => {
          submitServiceLock = false;
        })
        .finally(() => {
          submitLock = false;
        });
    };
    if (timeEnd) {
      // 时间到 自动提交
      getAllAnswer();
      console.log("answers3", answers);
      if (answers.length === 0) {
        console.log("没有作答直接返回home");
        router.push({
          path: "/home",
        });
      } else {
        console.log("提交答案", answers);
        await submitAnswer();
      }
      console.log(timeEnd);
      return;
    }
    if (submitLock) {
      return;
    }
    submitLock = true;
    normalMessageBox({
      title: "- 提交试卷 -",
      message: `<div style="padding-top: 30px">确定提交试卷？</div>`,
    })
      .then(() => {
        getAllAnswer();
        const paperQuestions = store.getters.allPaperQuestionsStatistics;
        const paperMockQuestions =
          store.getters.allMockPaperQuestionsStatistics;

        const answerSituation = paperMockQuestions.map((p: any) =>
          _.pick(p, ["name", "total_question_num", "total_answer_question_num"])
        );

        const answerSituationTAQN = paperQuestions.map((p: any) =>
          _.pick(p, ["name", "total_question_num", "total_answer_question_num"])
        );

        const answerSum = _.sumBy(
          answerSituation,
          (p: any) => p.total_answer_question_num
        );
        const answerSumTAQN = _.sumBy(
          answerSituationTAQN,
          (p: any) => p.total_answer_question_num
        );

        const questionsSum = _.sumBy(
          answerSituation,
          (p: any) => p.total_question_num
        );
        if (answerSum === 0) {
          const message =
            "本次模拟测评所有题目均未做答。<br/>是否退出测评？<br/>如退出，本次测评将不做记录。";
          normalMessageBox({
            title: "- 退出模拟测评 -",
            message,
          })
            .then(() => {
              router.push({
                path: "/home",
              });
            })
            .catch(() => {
              store.commit(MutationNames.CHANGE_MOCKEXAMEND, false);
              submitLock = false;
            });
          return;
        }
        if (answerSum !== questionsSum) {
          // const message = answerSituation
          const message = answerSituationTAQN
            .map(
              (p: any) =>
                `<p>已作答<span style="color:red">${
                  p.total_answer_question_num
                }</span>题,还剩<span style="color:red">${
                  p.total_question_num - p.total_answer_question_num
                }</span>题未答</p>`
            )
            .join("<br/>");
          normalMessageBox({
            title: "- 提交试卷 -",
            message,
          })
            .then(() => {
              submitAnswer();
            })
            .catch(() => {
              store.commit(MutationNames.CHANGE_MOCKEXAMEND, false);
              submitLock = false;
            });
          return;
        }
        submitAnswer();
      })
      .catch(() => {
        onCancel?.();
        submitLock = false;
        store.commit(MutationNames.CHANGE_MOCKEXAMEND, false);
      });
  };

  const showExamMessageBox = (
    option: ElMessageBoxOptions & { buttonSize?: string } = {}
  ) => {
    return ElMessageBox({
      title: "- 系统出错 -",
      message: "系统出错，无法进行测评，请联系监考老师处理！",
      customClass: "exam-message-box",
      confirmButtonText: "确定",
      buttonSize: "large",
      center: true,
      closeOnHashChange: true,
      ...option,
    });
  };

  const normalMessageBox = (params: any) => {
    const normalParams = {
      title: params.title,
      center: true,
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: params.message,
      showCancelButton: true,
      cancelButtonText: "取消",
      closeOnClickModal: false,
      customClass: "exam-score-message-box2",
    };
    return showExamMessageBox({ ...normalParams, ...params });
  };

  return {
    submitServiceLock,
    getExamPaper,
    submitPapers,
    showExamMessageBox,
  };
};
